import React from 'react';
import * as signalR from "@microsoft/signalr";
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux/es/exports";
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useOutsideClick } from "./customHooks/useOutsideClick";
import CompletedTodosPage from './Pages/CompletedTasksPage/CompletedTodosPage';
import DeletedTodosPage from './Pages/DeletedTasksPage/DeletedTodosPage';
import IncomingTodosPage from './Pages/IncomingTasksPage/IncomingTodosPage';
import LoginPage from './Pages/Login/LoginPage';
import RegisterPage from './Pages/Register/RegisterPage';
import TodayTask from './Pages/TodayTasks/TodayTasksPage';
import OffcanvasMobile from './OffcanvasMobile/offcanvasMobileApp';
import NotFoundPage from './Pages/NotFoundPage/NotFoundPage';
import LabelPage from './Pages/Tags/TagsPage';
import Settings from './Pages/SettingsPage/SettingsPage';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import { getAvatar} from "./asyncActions/avatarService";
import logo from './icons/svg/logo_timetask.svg';
import DDMenu from './DropDownMenu/DDMenu';
import './App.css';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
// import TaskSelection from "./GroupTaskEditing/TaskSelection";
import CalendarPage from './Pages/CalendarPage/CalendarPage';
import SuccessPage from './Pages/SuccessPage/SuccessPage.tsx';
import { ReactComponent as BurgerMenu } from "../src/icons/svg/menu_ic/burger_menu_mobile.svg";
import { ReactComponent as ArrowDown } from "../src/icons/svg/menu_ic/arrow_down_pagePicker.svg";
import DDselectPage from './DropDownMenu/SelectPageMobile/DDselectPage';
import { ReactComponent as CalendarSvg } from "./icons/svg/submenu_ic/calendar-active.svg";
import { ReactComponent as TodaySvg } from "./icons/svg/submenu_ic/element-1-active.svg";
import { ReactComponent as IncomingSvg } from "./icons/svg/submenu_ic/filled-active.svg";
import EditModalTask from './ModalWindow/EditTaskModal/EditModalTask';
import Button from './UIcomponents/Button/Button';
import request from './Requests/requests';
import { loadUserSettings } from './store/settingsReducer';
import Privacy from './Privacy/privacy';
import { connectionHubContext } from './Context/connectionHubContext';
import Cookies from 'universal-cookie';
import Feedback from './Feedback/Feedback';
import Loader from './Loader/Lodaer';
import RepeatTemplatePage from './Pages/RepeatTemplatePage/RepeatTemplatePage';
import SuccessGoogle from './Pages/SuccessGoogle/SuccessGoogle';


function App() {
  localStorage.setItem('interceptorTry', 'false');
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const ref = useRef();
  const [menuActiv, setMenuActiv] = useState(false);
  const [menuPage, setMenuPage] = useState(false);
  const [pageName, setPageName] = useState({ pageName: 'Мой день', index: 0 });
  const [windowScreen, setWindowScreen] = useState(window.screen.width);
  const [isDisabledHeaderBtns, setIsDisabledHeaderBtns] = useState(true);
  const [loader, setLoader] = useState(false);
  const [editTaskModalIcon, setEditTaskModalIcon] = useState(null);
  const urlTimerHub = (process.env.REACT_APP_API_BASEURL_HUB ?? 'https://api-demo.timetask.ru/') + 'TimerHub';
  const urlUpdateHub = (process.env.REACT_APP_API_BASEURL_HUB ?? 'https://api-demo.timetask.ru/') + 'TaskUpdateHub';
  const [connectionTimerHub, setConnectionTimerHub] = useState(null);
  const [connectionUpdateHub, setConnectionUpdateHub] = useState(null);
  const cookies = new Cookies();
  const refRefresh = useRef(null);
  const [isAuth, setIsAuth] = useState(false)
  const API_URL = process.env.REACT_APP_API_BASEURL ?? 'https://api-demo.timetask.ru/api/';
  const location = useLocation();
  const updatePageName = (payload) => {
    setPageName({
      pageName: payload.pageName,
      index: payload.index
    })
  }
  const arraySvg = [
    <TodaySvg style={{ width: '16px', height: '16px' }} />,
    <CalendarSvg style={{ width: '16px', height: '16px' }} />,
    <IncomingSvg style={{ width: '16px', height: '16px' }} />
  ];

  const arrayPages = [
    'Мой день',
    'Календарь',
    'Входящие задачи'
  ]
  const arrayLink = ['/', '/calendar', '/viewlist'];
  const stylesButtonLogin = { padding: '10px 20px' }
  const stylesTitleLogin = { lineHeight: 'normal' }
  const stylesButtonReg = { padding: '10px 25px', backgroundColor: 'transparent', border: '1px solid #5932EA' }
  const stylesTitleReg = { color: '#5932EA', lineHeight: 'normal' }
  let avaUrl = useSelector(state => state.settings.avatarUrl);
  useOutsideClick(ref, () => setMenuActiv(false), menuActiv);
  // const clearSelect = event => {
  //   if(event.ctrlKey){
  //       return;
  //   }else{
  //       TaskSelection("clear");
  //       return;
  //   }
  // }
  const refCloseSelectPage = useRef();
  useOutsideClick(refCloseSelectPage, () => setMenuPage(false), menuPage);

  const getTimeToRefresh = () => {
    const date = new Date()
    date.setMinutes(date.getMinutes() + 4)
    return date
  }

  const timeRemaining = () => {
    const dateUntil = new Date (localStorage.getItem('liveRefreshUntil'))
    const dateNow = new Date()
    const diff = dateUntil.getTime() - dateNow.getTime()
    return diff
  }

  const getToken = async () => {
    return await request.post(`${API_URL}Account/refresh`, {
      refreshToken: cookies.get('refreshToken')
    }).then((response) => {
      localStorage.setItem('liveRefreshUntil', getTimeToRefresh())
      localStorage.setItem('authToken', response.data.token)
      cookies.set('refreshToken', response.data.refreshToken, {maxAge: 24*60*60*7})
      setIsAuth(true)
      interval()})
  }

  const interval = () => {
    if (!refRefresh.current) {
      refRefresh.current = setInterval( async ()=>{
        await getToken().then(() => clearInterval(refRefresh.current))
      }, 4*60*1000 )
    }
  }
  
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      setIsAuth(false)
    }
  }, [localStorage.getItem('token')]) // eslint-disable-line react-hooks/exhaustive-deps

  // console.warn(new Date(localStorage.getItem('liveRefreshUntil')));

  const checkAuth = () => {
    if (localStorage.getItem('liveRefreshUntil') && (new Date(localStorage.getItem('liveRefreshUntil')).getTime() > new Date().getTime())) {
      setIsAuth(true)
      setLoader(false)
      interval(timeRemaining())
    } else {
      getToken().then(() => setLoader(false))
      .catch(() => setLoader(false))
    }
  }

  useEffect(()=> {
    setLoader(true)
    checkAuth();
    return () => {
      clearInterval(refRefresh.current)
    }
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  /*Set connection to hub*/
  const connectTimerHub = () => {
    const newConnectionTimerHub = new signalR.HubConnectionBuilder()
      .withUrl(urlTimerHub)
      .withAutomaticReconnect()
      .build()
    setConnectionTimerHub(newConnectionTimerHub)
  }

  const connectUpdateHub = () => {
    const newConnectionUpdateHub = new signalR.HubConnectionBuilder()
      .withUrl(urlUpdateHub)
      .withAutomaticReconnect()
      .build()
    setConnectionUpdateHub(newConnectionUpdateHub)
  }

  connectionTimerHub?.onclose(() => {
    connectTimerHub();
  });
  connectionUpdateHub?.onclose(() => {
    connectUpdateHub();
  });

  useEffect(() => {
    if (isAuth) {
      connectTimerHub()
      connectUpdateHub()
    }
    return () => {
      connectionTimerHub?.stop();
      connectionUpdateHub?.stop();
    }
  }, [isAuth]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isAuth) {
      const connectionHubStart = async () => {
        try {
          await connectionTimerHub?.start();
          await connectionTimerHub?.invoke("Join", localStorage.getItem('token'))
          console.log('SignalR connected...');
        } catch (error) {
          console.log(error);
          setTimeout(connectTimerHub, 5000);
        }
      }
      connectionHubStart();
    }
  }, [connectionTimerHub, isAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isAuth) {
      const connectionHubStart = async () => {
        try {
          await connectionUpdateHub?.start();
          await connectionUpdateHub?.invoke("Join", localStorage.getItem('token'))
          console.log('SignalR connected...');
        } catch (error) {
          console.log(error);
          setTimeout(connectUpdateHub, 5000);
        }
      }
      connectionHubStart();
    }
  }, [connectionUpdateHub, isAuth]); // eslint-disable-line react-hooks/exhaustive-deps
  /***************************/

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        document.title = 'Мой день'
        break;
      case '/calendar':
        document.title = 'Календарь'
        break;
      case '/viewlist':
        document.title = 'Входящие задачи'
        break;
      case '/profile':
        document.title = 'Профиль'
        break;
      case '/settings':
        document.title = 'Настройки'
        break;
      case '/statistics':
        document.title = 'Статистика'
        break;
      case '/completed':
        document.title = 'Выполненные'
        break;
      case '/removed_todo':
        document.title = 'Корзина'
        break;
      case '/tags':
        document.title = 'Метки'
        break;
      case '/login':
        document.title = 'Вход - Timetask'
        break;
      case '/login/':
        document.title = 'Вход - Timetask'
        break;
      case '/register':
        document.title = 'Регистрация - Timetask'
        break;
      case '/forgot_password':
        document.title = 'Восстановление пароля'
        break;
      default:
        document.title = 'Timetask'
        break;
    }
  }, [location.pathname])

  useEffect(() => {
    const handleUpdateScreenWidth = () => {
      setWindowScreen(window.screen.width)
    }

    window.addEventListener('resize', handleUpdateScreenWidth);

    return () => {
      window.removeEventListener('resize', handleUpdateScreenWidth);
    };
  }, [windowScreen])

  useEffect(() => {
    arrayLink.map((item, index) => {
      if (item === location.pathname) {
        updatePageName({
          pageName: arrayPages[index],
          index: index
        })
      }
      return index;
    })
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [location.pathname])

  useEffect(() => {
    if (isAuth) {
      dispatch(getAvatar());
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isAuth])

  useEffect(() => {
    if (isAuth) {
      request.get('/Settings/', {
        params: { UserId: localStorage.getItem('token') }
      })
        .then((response) => dispatch(loadUserSettings(response.data)))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isAuth])

  const handleLoginPage = () => {
    navigate('/')
  }

  const handleRegisterPage = () => {
    navigate('/register')
  }

  if(loader) {
    return <div style={{display: 'flex', justifyContent:'center', alignItems: 'center', height: '100vh', width: '100wh'}}>
      <Loader />
      </div>
  }

  return (
    <>
    {isAuth || localStorage.getItem('token') ? (
      <>
        <header id="Head" className='header'>
          <img className='header__logo' alt='logo' src={logo} style={{ cursor: 'pointer' }} onClick={() => navigate('/', { require: true })} />
          <ul className="header__menu">
          {windowScreen > 576 ? <Feedback/> : null}
            {
              windowScreen > 576 ?
              <button ref={ref} className='btn-menu' onClick={() => setMenuActiv(!menuActiv)}>
                  <img className='user-avatar' src={avaUrl} alt='' />
                </button> :
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <div ref={refCloseSelectPage} className='pagePicker' onClick={() => setMenuPage(!menuPage)}>
                    <span className='pagePicker__span'>
                      {arraySvg[pageName.index]}
                      <p>{pageName.pageName}</p>
                      <ArrowDown className={menuPage ? 'rotated-arrow' : 'unrotated-arrow'} />
                    </span>
                  </div>
                    {
                      menuPage ?
                      <DDselectPage arrayPages={arrayPages}
                      arrayLink={arrayLink}
                      currentLocation={location.pathname}
                      updatePageName={updatePageName} /> : null
                    }
                  <div style={{display: 'flex', gap: '10px'}}>
                    <Feedback/>
                    <button ref={ref} className='btn-menu__mobile' onClick={() => setMenuActiv(!menuActiv)}>
                      <BurgerMenu style={{width: '16px', height: '16px'}}></BurgerMenu>
                    </button>
                  </div>
                </div>
            }
            {
              windowScreen > 576 ?
                <DDMenu
                  active={menuActiv}
                  avatar={avaUrl}
                  setActive={setMenuActiv}
                /> :
                <OffcanvasMobile 
                  active={menuActiv}
                  setActive={setMenuActiv}
                  avatar={avaUrl}
                />
            }
          </ul>
        </header>
        <body>
          {/* {
            modalShow &&
            <ModalWindow updateModalState={updateModalState}
              modalShow={modalShow}
              title={titleModal}
              disabledCloseBtn={isDisabledCloseBtn}
              disabledOutsideClose={isDisabledOutsideClose}
              description={descriptionModal}
              logo={logoModal}
              buttons={buttonsModal} />
          } */}
          
        </body>
        <div>
          <connectionHubContext.Provider value={{timerHub: connectionTimerHub, updateHub: connectionUpdateHub}}>
            <Routes>
              <Route path="/" element={<TodayTask
                setIcon={setEditTaskModalIcon} />} />
              <Route path="/:id/modal=:bool" element={<><TodayTask setIcon={setEditTaskModalIcon} /><EditModalTask modalTitle={'Мой день'} editTaskModalIcon={editTaskModalIcon} /></>} />
              <Route path="/login/" element={<LoginPage setIsAuth={setIsAuth}/>} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/viewlist" element={<IncomingTodosPage setIcon={setEditTaskModalIcon} />} />
              <Route path="/viewlist/:id/modal=:bool" element={<><IncomingTodosPage setIcon={setEditTaskModalIcon} /><EditModalTask modalTitle={'Входящие задачи'} editTaskModalIcon={editTaskModalIcon} /></>} />
              <Route path="/completed" element={<CompletedTodosPage setIcon={setEditTaskModalIcon} />} />
              <Route path="/completed/:id/modal=:bool" element={<><CompletedTodosPage setIcon={setEditTaskModalIcon} /><EditModalTask disabled={true} modalTitle={'Выполненные'} editTaskModalIcon={editTaskModalIcon} /> </>} />
              <Route path="/calendar" element={<CalendarPage setIcon={setEditTaskModalIcon} />} />
              <Route path="/calendar/:id/modal=:bool" element={<><CalendarPage setIcon={setEditTaskModalIcon} /><EditModalTask modalTitle={'Календарь'} editTaskModalIcon={editTaskModalIcon} /> </>} />
              <Route path="/removed_todo" element={<DeletedTodosPage setIcon={setEditTaskModalIcon} />} />
              <Route path="/removed_todo/:id/modal=:bool" element={<><DeletedTodosPage setIcon={setEditTaskModalIcon} /><EditModalTask disabled={true} modalTitle={'Корзина'} editTaskModalIcon={editTaskModalIcon} /></>} />
              <Route path="/tags" element={<LabelPage />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/automation" element={<RepeatTemplatePage setIcon={setEditTaskModalIcon} />} />
              <Route path="/automation/:id/modal=:bool" element={<><RepeatTemplatePage setIcon={setEditTaskModalIcon} /><EditModalTask modalTitle={'Повторяющиеся задачи'} editTaskModalIcon={editTaskModalIcon} /> </>} />
              <Route path='/integrations' element={<Settings/>}></Route>
              <Route path="/oauth/callback/" element={<SuccessGoogle />} />
              <Route path='/profile' element={<Settings />} />
              <Route path='/test' element={<Settings />} />
              <Route path='/statistics' element={<Settings />} />
              <Route path='/statistics/:id/modal=:bool' element={<><Settings /><EditModalTask disabled={true} modalTitle={'Выполненные'} editTaskModalIcon={editTaskModalIcon} /></>} />
              <Route path="/*" element={<NotFoundPage />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path='/register_success' element={<SuccessPage title='Вы успешно зарегистрировались' typeIcon={1} />} />
            </Routes>
          </connectionHubContext.Provider>
        </div>
      </>
    ) : (
      <>
        <header className='header'>
          <img className='header__logo-nologin' alt='logo' src={logo} style={{ cursor: 'pointer' }} onClick={() => navigate('/', { require: true })} />
          {window.screen.width > 576 ?
            <div className='header__buttons' style={isDisabledHeaderBtns ? { display: 'none' } : { display: 'flex' }}>
              <Button title='Регистрация' stylesButton={stylesButtonReg} stylesTitle={stylesTitleReg} onClickFunc={handleRegisterPage} />
              <Button title='Войти' stylesButton={stylesButtonLogin} stylesTitle={stylesTitleLogin} onClickFunc={handleLoginPage} />
            </div> : null}
        </header>
        <Routes>
          <Route path="/register" element={<RegisterPage setIsAuth={setIsAuth}/>} />
          <Route path="/login/" element={<LoginPage setIsAuth={setIsAuth}/>} />
          <Route path="/" element={<LoginPage setIsAuth={setIsAuth}/>} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/ResetPassword/" element={<ResetPassword />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/*" element={<NotFoundPage setIsDisabledHeaderBtns={setIsDisabledHeaderBtns} />} />
        </Routes>
      </>
    )
    }
    </>
  )
}

export default App;
