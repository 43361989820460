import React, { ReactElement, useRef } from "react";
import './DropDown.css'
import { useOutsideClick } from "../../customHooks/useOutsideClick";
import {ReactComponent as Picked} from "./static/picked.svg";

interface DropDownProps {
    children?: ReactElement,
    isActive: boolean,
    updateIsActive: Function,
    refLink?: any,
    position?: object,
    optionArr: string[],
    defaultValue: string | number | null,
    onClickFunc: Function
}

export default function DropDown({children, optionArr, isActive, updateIsActive, refLink, position, defaultValue, onClickFunc}: DropDownProps) {
    const refDropDown = useRef(null)
    useOutsideClick(refDropDown, () => updateIsActive(false), isActive)
    
    
    return (
        <div ref={refLink ?? refDropDown} style={isActive ? position : {}} className={isActive ? "DropDown" : "hiddenDropDown"} >
            <ul className="DropDown__option-list">
                {
                    optionArr.map((el, index) => {
                        return (
                            <li className="DropDown__option-el"
                                onClick={() => {
                                    onClickFunc({element: el, index: index})
                                    updateIsActive(false)
                                }} 
                                key={index}>{el} <span style={{visibility: defaultValue === index ? 'visible' : 'hidden'}}><Picked/></span></li>

                        )
                    })
                }
            </ul>
            {children}
        </div>
    )
}